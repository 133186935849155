import { CheckCircleIcon } from '@swe/shared/ui-kit/components/icon';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';

import { RequirementStatus } from 'common/entities/documents/Requirements';

type DocumentSectionProps = {
  title: string;
  description: string;
  status: RequirementStatus;
  onActionClick: () => void;
};

const DocumentSection = ({ title, description, status, onActionClick }: DocumentSectionProps) => {
  return (
    <SectionHeading
      subHeading={description}
      button={
        status !== RequirementStatus.Fulfilled
          ? {
              children: status === RequirementStatus.RequiresAction ? 'Update Documents' : 'Upload',
              color: status === RequirementStatus.RequiresAction ? 'warning' : 'primary',
              onClick: onActionClick,
            }
          : undefined
      }
      badge={
        status === RequirementStatus.Fulfilled
          ? {
              icon: CheckCircleIcon,
              color: 'success',
            }
          : undefined
      }
    >
      {title}
    </SectionHeading>
  );
};

export type { DocumentSectionProps };
export { DocumentSection };
