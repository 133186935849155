import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { Cont404 } from 'common/containers/404';
import { useStoreConfig } from 'common/providers/config';
import { Routes } from 'common/router/constants';
import { CheckoutRequiredDocuments } from 'domains/checkout/containers/required-documents';

const TITLE = 'Required Documents';

const CheckoutAddDocumentsPage: ApplicationPage = () => {
  const { isOnlineOrderingDisabled } = useStoreConfig();

  if (isOnlineOrderingDisabled) {
    return <Cont404 />;
  }

  return (
    <Page
      {...DEFAULT_AUTHORIZED_PAGE_PROPS}
      flex={1}
      header={{
        title: TITLE,
        description: 'The following documents need to be uploaded for purchasing',
        defaultBackRoute: Routes.Checkout,
        showBreadcrumbs: false,
      }}
    >
      <CheckoutRequiredDocuments />
    </Page>
  );
};

CheckoutAddDocumentsPage.getMeta = () => {
  return { title: TITLE };
};

CheckoutAddDocumentsPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
  subheader: null,
});

export default CheckoutAddDocumentsPage;
