import Button from '@swe/shared/ui-kit/components/button';
import Loader from '@swe/shared/ui-kit/components/loader';

import Stack from '@swe/shared/ui-kit/components/stack';

import { useCallback } from 'react';

import styles from './styles.module.scss';

import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';
import { useDocumentsRequirements } from 'common/use-cases/documents/use-documents-requirements';
import { DocumentSection } from 'domains/checkout/containers/required-documents/components/document-section';
import { DocumentCategory } from 'entities/documents/documents-v2';

const getTitle = (category: DocumentCategory) =>
  ({
    [DocumentCategory.Identity]: 'Government-issued ID',
    [DocumentCategory.Medical]: 'Medical Documents',
    [DocumentCategory.Additional]: 'Additional Documents',
  })[category];

const CheckoutRequiredDocuments = () => {
  const { requirements, isLoading } = useDocumentsRequirements();
  const navigate = useRouterNavigate();

  const toCheckout = useCallback(() => navigate({ pathname: Routes.Checkout }), [navigate]);

  if (isLoading || !requirements) {
    return (
      <Loader
        size="xl"
        center
      />
    );
  }

  return (
    <div className={styles.root}>
      <Stack
        spacing="none"
        divider
      >
        {requirements.nonEmptyRequirements.map((requirement) => (
          <DocumentSection
            key={requirement.category}
            title={getTitle(requirement.category)}
            description={requirement.documentNamesString}
            status={requirement.status}
            onActionClick={() =>
              navigate({ pathname: Routes.CheckoutDocumentsCategory, query: { category: requirement.category } })
            }
          />
        ))}
      </Stack>
      {requirements.isFulfilled && (
        <Button
          ariaLabel="backToPlaceOrder"
          block
          onClick={toCheckout}
        >
          Proceed to Place Order
        </Button>
      )}
    </div>
  );
};

export { CheckoutRequiredDocuments };
